import { Confirmation, Stage } from '.';
import { LockOpenIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
    fetchInvestments,
    requestToken,
    saveAccountSettings,
} from '@etica-js/api/src/appState/actions';
import { useFeatures } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';

import form from '../../assets/styles.module.scss';
import { Aside, AsideProps } from '../ui/aside';
import { DatePicker } from '../ui/datepicker';
import { LoadingAnimation } from '../ui/loading';
import { TokenSelector } from './parts/token';

type Props = {
    onClose: AsideProps['onClose'];
};

export const LOCK_IN_PERIODS = {
    '1m': '1 month',
    '2m': '2 months',
    '3m': '3 months',
    '4m': '4 months',
    '5m': '5 months',
    '6m': '6 months',
    '7m': '7 months',
    '8m': '8 months',
    '9m': '9 months',
    '10m': '10 months',
    '11m': '11 months',
    '12m': '1 year',
    '18m': '1.5 years',
    '24m': '2 years',
    '36m': '3 years',
    '48m': '4 years',
    '60m': '5 years',
};

export const AccountSettings: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const account = useMemo(() => {
        return appCtx
            .process('GET_ACCOUNTS')
            .find((account) => account.number === appCtx.ui.openModal.target);
    }, [appCtx]);
    const { enable_transaction_pin } = useFeatures();
    const [tokenMethod, setTokenMethod] = useState('sms');
    const [token, setToken] = useState('');
    const [complete, setComplete] = useState(false);
    const [stage, setStage] = useState<Stage>(undefined);
    const [loading, setLoading] = useState(false);
    const [accountAlias, setAccountAlias] = useState(account?.alias);
    const [lockIn, setLockIn] = useState<string | undefined>(
        account?.lock_in_period
    );
    const [lock_in_use_date, setLockInUseDate] = useState(
        !!account?.lock_in_date
    );
    const [lock_in_date, setLockInDate] = useState<Date | undefined>(
        account?.lock_in_date
            ? new Date(Date.parse(account.lock_in_date))
            : undefined
    );

    useEffect(() => {
        if (enable_transaction_pin) {
            setTokenMethod('pin');
        }
    }, [enable_transaction_pin]);

    const flipLockIn = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            lock_in_use_date ? setLockInDate(undefined) : setLockIn(undefined);

            setLockInUseDate((on) => !on);
        },
        [lock_in_use_date]
    );

    const unlockAccount = useCallback((event: React.FormEvent) => {
        event.preventDefault();
        setLockIn('');
        setLockInUseDate(false);
    }, []);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!account) {
            toast.error('Account could not be found');
            return;
        }

        setLoading(true);
        if (stage === undefined) {
            (tokenMethod === 'pin'
                ? Promise.resolve()
                : requestToken(tokenMethod)
            )
                .then(() => {
                    setStage({
                        type: 'confirmation',
                        message: 'Please confirm your details below',
                        setToken,
                        tokenMethod,
                        data: [
                            {
                                label: 'Account',
                                value: account?.label,
                            },
                            {
                                label: 'Alias',
                                value: accountAlias,
                            },
                            {
                                label: 'Lock In Period',
                                value: lock_in_use_date
                                    ? 'Up to ' +
                                      lock_in_date?.toLocaleDateString()
                                    : lockIn
                                    ? Object.entries(LOCK_IN_PERIODS).find(
                                          ([k, v]) => k === lockIn
                                      )?.[1] ?? lockIn
                                    : 'None',
                            },
                        ],
                    });
                })
                .finally(() => setLoading(false));
            return;
        }

        saveAccountSettings({
            account: account.number,
            alias: accountAlias,
            lock_in_period: lock_in_use_date ? undefined : lockIn,
            lock_in_date: lock_in_use_date ? lock_in_date : undefined,
            token,
            token_method: tokenMethod,
        })
            .then((result) => {
                if (result.is_error) {
                    toast.error('Failed - ' + result.message);
                    return;
                }

                if (result.data?.messages?.length) {
                    result.data.messages.forEach((message) => {
                        toast.warning(message);
                    });
                } else {
                    toast.success('Saved successfully');
                }

                fetchInvestments(appCtx.dispatch);

                setComplete(true);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Aside onClose={props.onClose} success={complete}>
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">
                    Customize Investment Account
                </h3>

                <div className={form.infoBox + ' w-full py-5'}>
                    {account?.label}
                </div>
                <form onSubmit={onSubmit} className="w-full">
                    {stage && stage.type === 'confirmation' && (
                        <Confirmation {...stage} />
                    )}
                    <div className="w-full">
                        {!stage && (
                            <>
                                <div className={form.fieldset}>
                                    <label htmlFor="">
                                        Account Alias{' '}
                                        <span className={form.small}>
                                            You can give your account a name or
                                            a goal, e.g. school fees account
                                        </span>
                                    </label>
                                    <input
                                        defaultValue={account?.alias}
                                        type="text"
                                        onChange={(e) =>
                                            setAccountAlias(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </div>
                                {!account?.product.lock_in_period && (
                                    <div className={form.fieldset}>
                                        <label htmlFor="">
                                            Lock in period{' | '}
                                            {account?.lock_in_date ? (
                                                <button
                                                    className="text-green-400"
                                                    onClick={unlockAccount}
                                                >
                                                    <LockOpenIcon className="w-4 inline" />{' '}
                                                    Unlock Account
                                                </button>
                                            ) : (
                                                <button
                                                    className="text-cyan-400"
                                                    onClick={flipLockIn}
                                                >
                                                    {lock_in_use_date
                                                        ? 'Select lock in period instead'
                                                        : 'Select date instead'}
                                                </button>
                                            )}
                                            <span className={form.small}>
                                                You can customize how long you
                                                want to keep your investment
                                                before you can withdraw
                                            </span>
                                        </label>
                                        {lock_in_use_date ? (
                                            <DatePicker
                                                wrapperClassName="w-full"
                                                placeholderText="Lock in end date"
                                                selected={lock_in_date}
                                                onChange={(date) =>
                                                    setLockInDate(date)
                                                }
                                                isClearable={true}
                                            />
                                        ) : (
                                            <select
                                                name="lock_in_period"
                                                onChange={(e) =>
                                                    setLockIn(
                                                        e.currentTarget.value
                                                    )
                                                }
                                                value={lockIn}
                                            >
                                                <option value="">None</option>
                                                {Object.entries(
                                                    LOCK_IN_PERIODS
                                                ).map(([key, val]) => (
                                                    <option
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {val}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        <div className={form.fieldset}>
                            {!enable_transaction_pin && !stage && (
                                <TokenSelector
                                    className="w-full"
                                    onTokenMethodChange={setTokenMethod}
                                />
                            )}
                        </div>
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} />{' '}
                                {stage ? 'Continue' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
