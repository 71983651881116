import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
    getSectretQuestions,
    Question,
} from '@etica-js/api/src/appState/actions';

import { LoginForm } from '../auth/login';

export type Answer = { id: string; question: string; answer: string };

type Props = {
    onSelectQuestions: (answers: ReadonlyArray<Answer>) => void;
};

export const SecretQuestions: React.FC<Props> = (props) => {
    const [questions, setQuestions] = useState<ReadonlyArray<Question>>([]);
    const [answers, setAnswers] = useState<ReadonlyArray<Answer>>([]);

    const submitQuestions = () => {
        if (answers.length < 3) {
            toast.error('At least three questions must be answered');
            return;
        }

        props.onSelectQuestions(answers);
        toast.success('Questions saved, now create your PIN');
    };

    const [selectedQuestion, setSelectedQuestion] = useState<string>();
    const [enteredAnswer, setEnteredAnswer] = useState<string>();

    const remainingQuestions = useMemo(
        () =>
            questions
                ?.filter(
                    (q) => !answers.map((a) => a.id).includes(q.id?.toString())
                )
                ?.map((q) => ({ value: q.id.toString(), label: q.question })) ??
            [],
        [questions, answers]
    );

    // Select the first question as default
    useEffect(() => {
        if (remainingQuestions && !selectedQuestion) {
            setSelectedQuestion(remainingQuestions[0]?.value);
        }
    }, [remainingQuestions, selectedQuestion]);

    const saveQuestion = (answer: {
        id: string;
        question: string;
        answer: string;
    }) => {
        if (!answer.id || !answer.answer) {
            toast.warning('Select a question and answer it before saving');
            return;
        }

        if (answers.find((a) => a.id === answer.id)) {
            toast.error(
                'You have already answered this question, select another one'
            );
            return;
        }

        const newAnswers = [...answers, answer];
        setAnswers(newAnswers);
        if (newAnswers.length < 3) {
            toast.info(
                `Saved successfully. ${
                    3 - newAnswers.length
                } more questions to go`
            );
        } else {
            toast.info(
                'You have answered at least three questions, click continue to go to next step'
            );
        }

        // clear form
        setSelectedQuestion(undefined);
        setEnteredAnswer('');
    };

    const removeAnswer = (id: string) => {
        setAnswers(answers.filter((ans) => ans.id !== id));
        toast.success('Answer removed successfully');
    };

    useEffect(() => {
        getSectretQuestions().then((res) => {
            if (res.is_error) {
                toast.error('Could not fetch secret questions');
                return;
            }

            setQuestions(res.data?.questions ?? []);
            setSelectedQuestion(undefined);
        });
    }, []);

    return (
        <LoginForm
            title={''}
            description={''}
            fields={[
                {
                    id: 'question',
                    type: 'select',
                    label: 'Select question',
                    placeholder: 'Select the question',
                    choices: remainingQuestions,
                    value: selectedQuestion,
                    onChange: (event) => {
                        setSelectedQuestion(event.currentTarget.value);
                    },
                },
                {
                    id: 'answer',
                    type: 'text',
                    label: 'Enter your answer',
                    placeholder: 'Enter your answer',
                    value: enteredAnswer ?? '',
                    onChange: (event) => {
                        setEnteredAnswer(event.currentTarget.value);
                    },
                },
            ]}
            links={[]}
            submit={
                questions.length >= 3
                    ? { value: 'Continue', fn: submitQuestions }
                    : undefined
            }
            beforeForm={
                <div>
                    <label htmlFor="" className="text-gray-600 text-sm mb-2">
                        Select and answer three questions below then click the
                        save button
                    </label>
                    <table className="table table-auto mb-5 border w-full text-gray-800">
                        <thead>
                            <tr className="border">
                                <th className="text-left px-2">Question</th>
                                <th className="text-left px-2">Answer</th>
                                <th className="text-left px-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {answers.map((ans) => (
                                <tr key={ans.id} className="text-sm border">
                                    <td className="px-2">{ans.question}</td>
                                    <td className="px-2">{ans.answer}</td>
                                    <td className="px-2">
                                        <button
                                            className="small bg-red-400 text-white button"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                removeAnswer(ans.id);
                                            }}
                                        >
                                            X
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        >
            <button
                className="secondary button w-full mb-2"
                onClick={(event) => {
                    event.preventDefault();
                    saveQuestion({
                        id: selectedQuestion ?? '',
                        answer: enteredAnswer ?? '',
                        question:
                            questions.find(
                                (q) =>
                                    q.id.toString() ===
                                    selectedQuestion?.toString()
                            )?.question ?? 'Not found',
                    });
                }}
            >
                Add question
            </button>
        </LoginForm>
    );
};
